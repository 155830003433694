body {
  margin: 0;
  font-family: halyard-text, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit!important;
  display: inline;
  text-decoration: none!important;
}

p {
  font-family: halyard-text, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
}

#about {
  max-width: 40%;
  text-align: center;
  margin: auto;
}

ul {
  list-style: none;
  font-family: halyard-display, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  text-align: left;
}
ul li::before {
  content: "";
  background-image: url(https://img.icons8.com/material-sharp/24/null/starburst-shape.png);
  background-size: contain;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  position: relative;
  top: 0.1rem;
  margin-right: 0.2rem;
}

li {
  display: inline-block;
  margin-left: 5%;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000000;
}

.onHover:hover {
  filter: brightness(115%);
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}